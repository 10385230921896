import { Box, Button, CircularProgress, Grid, makeStyles, ThemeProvider, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
// import BlockIcon from '@material-ui/icons/Block';
// import CheckIcon from "@material-ui/icons/Check";
import { kivitCumplimiento } from "../styles/common";
import { ArrowDownward } from "@material-ui/icons";
import { useParams } from "react-router";
import GoBackButton from "../components/general/GoBackButton";
import useStagesState from "../store/stagesState";
import { ServiceStage } from "../core/models/service.models";
import StageNavigation from "../components/stages/comps/StageNavigation";
import useGlobalState from "../store/globalState";
//import StageDocumentsCounters from "../components/stages/comps/StageDocumentsCounters";
import DownloadDocsDialog from "../components/stages/comps/DownloadDocsServiceDialog";
import StageGroupCard from "../components/stages/comps/StageGroupCard";
import StageContractCard from "../components/stages/comps/StageContractCard";
//import { Counter } from "../components/stages/types";
import { useHandleModal } from "../components/stages/hooks/useHandleModal";
import { sliceText } from "../core/utils/utils";
import StageSummaryDialog from "../components/stages/comps/StageSummaryDialog";

const MultiStagePage = () => {
  const { serviceId }: any = useParams();
  const classes = useStyles();
  //const theme = useTheme();
  const { currentService, getService, setCurrentService } = useStagesState();
  const mainCompanyId = useGlobalState((s) => s.mainCompany?.id);
  const mainCompany = useGlobalState((s) => s.mainCompany);
  //const [counters, setCounters] = useState<Counter[]>([]);
  const [showDownloadDocsDialog, onShowDownloadDocsDialog, onCloseDownloadDocsDialog] = useHandleModal();
  const [showStageSummaryDialog, onShowStageSummaryDialog, onCloseStageSummaryDialog] = useHandleModal();
  const [loading, setLoading] = useState(false);
  const [selectedServiceStageIdx, setSelectedServiceStageIdx] = useState<number>(0);
  const [selectedServiceStage, setSelectedServiceStage] = useState<ServiceStage>(null);
  const serviceStages = currentService?.ntp?.documentSet?.stages;
  const companyType = currentService?.provider === mainCompanyId ? "Cliente" : "Proveedor";
  const companyName = companyType === "Cliente" ? currentService?.clientName : currentService?.providerName;
  const currentContract = currentService?.ntp?.contract;

  const idCompanyService = currentService?.owner;
  const idServiceStage = currentService?.serviceTypeId;

  function onDownloadNominaXls() {
    window.open("/docs/Lista_nomina.xlsx", "_blank");
  }

  console.log("#currentService",currentService)
  useEffect(() => {
    if (serviceId) {
      setLoading(true);
      getService(serviceId).finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceId]);

  useEffect(() => {
    if (serviceStages?.length) {
      setSelectedServiceStage(serviceStages[selectedServiceStageIdx]);
    
    }
  }, [currentService, selectedServiceStageIdx]);

  useEffect(() => {
    //setCounters(mapStageCounters(selectedServiceStage, theme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceStage]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => setCurrentService(null), []);


const hasExpediente = () => {
  const regex = /expediente/i;
  if (regex.test(selectedServiceStage.name)){
    return true;
   } else {
    return false;
   }
}

 
  return (
    <>
      <ThemeProvider theme={kivitCumplimiento}>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end" style={{ gap: "1rem" }}>
          <Grid item>
            <GoBackButton />
          </Grid>
          {/* <StatusBar value={+(selectedServiceStage?.percent || 0).toFixed(2)} stageCompleted /> */}
        </Box>

        {!selectedServiceStage && loading && (
          <div className={classes.placeholderWrapper}>
            <CircularProgress color="primary" />
          </div>
        )}

        {!loading && !selectedServiceStage?.groups?.length && (
          <div className={classes.placeholderWrapper}>
            <Typography variant="subtitle2">No se encontraron datos</Typography>
          </div>
        )}

        {!!selectedServiceStage && (
          <Box display="flex" justifyContent="space-between" alignItems="center" py={2} style={{ gap: "1rem" }}>
            {/* <Typography variant="h3" color="secondary">
              {capitalizeFirstLetter(selectedServiceStage?.name)}
            </Typography> */}
            {/* <StageDocumentsCounters countersList={counters} companyType={companyType} companyName={companyName} /> */}

            <Typography variant="h3" color="textPrimary">
              {sliceText(companyName, 36)}
            </Typography>

            <div style={{ display: "flex", gap: "1rem" }}>
            { hasExpediente() ? "" : 
              <Button
              variant="contained"
              color="primary"
              style={{ padding: ".5rem 1rem" }}
              onClick={onShowStageSummaryDialog}
            >
              VER RESUMEN
            </Button>
            }
              {/*
                <Button
                variant="contained"
                color="secondary"
                style={{ padding: ".5rem 1rem" }}
                endIcon={<ArrowDownward fontSize="inherit" />}
                onClick={onDownloadNominaXls}
              >
                DESCARGAR <br /> XLS LISTA NOMINA
              </Button>*/}
              
            

              <Button
                variant="contained"
                color="secondary"
                style={{ padding: ".5rem 1rem" }}
                endIcon={<ArrowDownward fontSize="inherit" />}
                onClick={onShowDownloadDocsDialog}
              >
                DESCARGAR <br /> DOCUMENTOS
              </Button>
            </div>
          </Box>
        )}

        {Boolean(serviceStages?.length) && (
          <>
            {/* ---------------------------- STAGE NAVIGATION ---------------------------- */}
            <StageNavigation
              stages={serviceStages?.map((stage, idx) => ({ label: stage.name }))}
              value={selectedServiceStageIdx}
              onChange={(newIdx) => setSelectedServiceStageIdx(newIdx)}
            />
            {/* ------------------------------ service title ------------------------------ */}
            <Grid container style={{ backgroundColor: "#fff", padding: "1rem", marginTop: "1rem", marginBottom: "1rem" }}>
              <Typography variant="h5">
                <span style={{ fontWeight: "lighter" }}>Servicio:</span> {currentService?.name || "---"}
              </Typography>
            </Grid>
            {/* ------------------------------ CONTRACT ROW ------------------------------ */}
            <StageContractCard contract={currentContract} service={currentService} refresh={() => getService(serviceId)} />

            {/* ------------------------------- GROUPS ROWS ------------------------------ */}
            {selectedServiceStage?.groups?.map((group, idx) => (
              <StageGroupCard 
                key={group.name + idx} 
                group={group} 
                refresh={() => getService(serviceId)}
                idCompanyService={idCompanyService}
                idServiceStage={idServiceStage}
              />
            ))}
          </>
        )}

        {/* ----------------------------- MODALS & OTHERS ---------------------------- */}
        {showDownloadDocsDialog && (
          <DownloadDocsDialog
            stage={selectedServiceStage as any}
            companyId={mainCompanyId}
            serviceId={serviceId}
            onClose={onCloseDownloadDocsDialog}
          />
        )}

        {showStageSummaryDialog && (
          <StageSummaryDialog onClose={onCloseStageSummaryDialog} serviceId={serviceId} selectedStage={selectedServiceStage} />
        )}
      </ThemeProvider>
    </>
  );
};

export default MultiStagePage;

const useStyles = makeStyles((theme) => ({
  placeholderWrapper: {
    width: "100%",
    height: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
