import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useCatalogoState from '../../../../../store/generadores/catalogoState';
import useCustomToast from "../../../../../core/hooks/useCustomToast";
import useGeneratorClientState from '../../../../../store/generadores/generadorClientState';
import useGlobalState from '../../../../../store/globalState';
import useUiState from '../../../../../store/uiState';
import { GeneradoresServices } from '../../../../../core/services/generadores.service';
import useGeneralFormCompleteState from '../../../../../store/generadores/generalFormState';

interface Sheet {
  name: string;
  rowAxList: {
    cellAxList: string[];
    cssList?: string;
  }[];
}

interface Row {
  originalIndex?: number;
  cellAxList: string[];
  cssList?: string;
}

interface TableProps {
  data: Row[];
  sheetIndex: number;
  quantities: { [key: number]: { [key: number]: number } };
  handleQuantityChange: (sheetIndex: number, rowIndex: number, value: number) => void;
  getTotal: (row: Row, sheetIndex: number, rowIndex: number) => number;
}

interface SummaryProps {
  sheets: Sheet[];
  items: {
    material: string;
    sheetName: string;
    rowIndex: number;
    row: {
      cellAxList: string[];
    };
    quantity: number;
    total: number;
  }[];
  onSendSummary: (summaryData: any) => void;
  readyOnly?: boolean;
}

interface TabsProps {
  sheets: Sheet[];
  readyOnly?: boolean;
  isAdmin?: boolean;
}

const Tabs: React.FC<TabsProps> = ({ sheets , readyOnly } : TabsProps) => {
  
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const [quantities, setQuantities] = useState({});
  const { getExternalTreeCatalogue , externalTreeCatalogue} = useGeneratorClientState();
  const { mainCompany  } = useGlobalState();
  const companyId = mainCompany?.id;
  const allSheets = sheets;
  const toast = useCustomToast();


 /* const handleQuantityChange = useCallback((sheetIndex, rowIndex, value) => {
    setQuantities(prevQuantities => {
      const updatedQuantities = { ...prevQuantities };
      if (!updatedQuantities[sheetIndex]) {
        updatedQuantities[sheetIndex] = {};
      }
      updatedQuantities[sheetIndex][rowIndex] = value;
      return updatedQuantities;
    });
  }, []);*/

  const handleQuantityChange = useCallback((sheetIndex, rowIndex, value) => {
    setQuantities(prevQuantities => {
        const updatedQuantities = { ...prevQuantities };
        
        // Verifica si ya existe el sheetIndex en updatedQuantities
        if (!updatedQuantities[sheetIndex]) {
            updatedQuantities[sheetIndex] = {};
        }

        // Actualiza el valor de la cantidad en la fila específica
        updatedQuantities[sheetIndex][rowIndex] = value;

        return updatedQuantities;
    });
}, []);


  /*const getTotal = useCallback((row, sheetIndex, rowIndex) => {
    const quantity = quantities[sheetIndex]?.[rowIndex] || 0;
    const price = parseFloat(row.cellAxList[5]) || 0; // precio en el index 5
    return quantity * price;
  }, [quantities]);*/

  const getTotal = useCallback((row, sheetIndex, rowIndex) => {
    const quantity = quantities[sheetIndex]?.[rowIndex] || 0;
    const price = parseFloat(row.cellAxList[5]) || 0; // precio en el index 5
    const total = quantity * price;
  
    // Retorna el total como número
    return total;
  }, [quantities]);
  
  
  const selectedItems = useCallback(() => {
    const items = [];
    console.log("items",items)
    filteredSheets.forEach((sheet, sheetIndex) => {
      sheet.rowAxList.forEach((row, rowIndex) => {
        const quantity = quantities[sheetIndex]?.[rowIndex] || 0;
        if (quantity > 0) {
          items.push({ sheetName: sheet.name,material:row.cellAxList[0], rowIndex, row, quantity, total: getTotal(row, sheetIndex, rowIndex) });
        }
      });
    });
    return items;
  }, [ quantities, getTotal]);

  useEffect(() => {
    getExternalTreeCatalogue(companyId);
  }, [companyId, getExternalTreeCatalogue]);

  
  let catalogue = [];

  if (externalTreeCatalogue) {
    try {
      const parsedCatalogue = JSON.parse(externalTreeCatalogue);
      if (parsedCatalogue && Array.isArray(parsedCatalogue.catalogue)) {
        catalogue = parsedCatalogue.catalogue;
      }
    } catch (error) {
      console.error('Error parsing externalTreeCatalogue:', error);
    }
  }

  const catalogueRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  const matchesCatalogue = (tabName) => {
    console.log(tabName);
    return catalogue.some(item => {
      const regex = new RegExp(catalogueRegExp(item).replace(/\s+/g, '').toLowerCase());
      return regex.test(tabName.replace(/\s+/g, '').toLowerCase());
    });
  };

  // Aplicamos filtro si es necesario , validar que pestañas puede ver
  const filteredSheets = readyOnly ? sheets : sheets.filter(sheet => matchesCatalogue(sheet.name));

  const getSheetName = (name: string) => {
    if (name === "Destajo CHC") {
      return "Destajo de empresarial";
    } else if (name === "Mano de obra CHC") {
      return "Mano de obra de empresarial";
    }
  
    return name;
  };

  return (
    <div>
      <ul className={classes.tabList}>
        {/*sheets.map((sheet, index) => (
          <li
            key={index}
            className={`${classes.tabListItem} ${activeTab === index ? 'active' : ''}`}
            onClick={() => setActiveTab(index)}
          >
            {sheet.name}
          </li>
        ))*/}
         {filteredSheets.map((sheet, index) => (
        <li
          key={index}
          className={`${classes.tabListItem} ${activeTab === index ? 'active' : ''}`}
          onClick={() => setActiveTab(index)}
        >
          {getSheetName(sheet.name)}        
        </li>
      ))}
        <li
          className={`${classes.tabListItem} ${activeTab === filteredSheets.length ? 'active' : ''}`}
          onClick={() => setActiveTab(filteredSheets.length)}
        >
          Resumen
        </li>
      </ul>
      <div className={classes.tabContent}>
        {filteredSheets.map((sheetP, sheetIndex) => (
          <div
            key={sheetIndex}
            className={`${classes.tabPanel} ${activeTab === sheetIndex ? 'active' : ''}`}
          >
            <Table
              data={sheetP.rowAxList}
              sheetIndex={sheetIndex}
              quantities={quantities}
              handleQuantityChange={handleQuantityChange}
              getTotal={getTotal}
            />
          </div>
        ))}
        <div className={`${classes.tabPanel} ${activeTab === filteredSheets.length ? 'active' : ''}`}>
          <Summary sheets={filteredSheets} items={selectedItems()} onSendSummary={null} readyOnly={readyOnly}/>
        </div>
      </div>
    </div>
  );
};

const Table: React.FC<TableProps> = ({ data, sheetIndex, quantities, handleQuantityChange, getTotal }) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = useState("");

  const isSubtitle = (row: string[]) => {
    const nonEmptyCells = row.filter(cell => cell !== "");
    return nonEmptyCells.length === 1 || (nonEmptyCells.length === 2 && row[0] === "'");
  };

  // Agregamos 'originalIndex' a cada fila para mantener referencia al índice original
  const dataWithIndex = data.map((row, index) => ({
    ...row,
    originalIndex: index, // Mantén el índice original
  }));

  // Filtramos los datos usando 'dataWithIndex' en lugar de 'data' directamente
  const filteredData = dataWithIndex.filter(row => {
    return row.cellAxList.some(cell => 
      cell.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const clearSearch = () => {
    setSearchTerm("");
  }

  return (
    <>
      <div className={classes.searchContainer}>
        <input
          type="text"
          placeholder="Buscar..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={classes.searchInput}
        />

        <button onClick={clearSearch} className={classes.clearButton}>
          Limpiar
        </button>
      </div>

      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th}>MATERIAL (NSC) / SKU</th>
            <th className={classes.th}>CONCEPTO</th>
            <th className={classes.th}>DESCRIPCIÓN</th>
            <th className={classes.th}>UNIDAD</th>
            <th className={classes.th}>CANTIDAD</th>
            <th className={classes.th}>PRECIO UNITARIO MXN</th>
            <th className={classes.th}>TOTAL</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, rowIndex) => {
            const subtitleRow = isSubtitle(row.cellAxList);
            const originalIndex = row.originalIndex; // Usamos el índice original en lugar del índice filtrado

            return (
              <tr key={originalIndex} className={quantities[sheetIndex]?.[originalIndex] > 0 ? classes.selected : ''}>
                {row.cellAxList.map((cell, cellIndex) => {
                  if (subtitleRow) {
                    if (cell !== "") {
                      return (
                        <td key={cellIndex} colSpan={7} className={classes.td}>
                          <span className={classes.subtitle}>{cell}</span>
                        </td>
                      );
                    }
                    return null;
                  }

                  if (cellIndex > 5) return ;

                  if (cellIndex === 4) {
                    return (
                      <td key={cellIndex} className={classes.td}>
                        <input
                          type="number"
                          value={quantities[sheetIndex]?.[originalIndex] !== undefined ? quantities[sheetIndex][originalIndex] : ""}
                          onChange={(e) => {
                            const value = e.target.value === "" ? 0 : parseInt(e.target.value, 10);
                            handleQuantityChange(sheetIndex, originalIndex, value); // Usamos originalIndex aquí
                          }}
                          onFocus={(e) => {
                            if (e.target.value === "0") e.target.value = "";
                          }}
                          onBlur={(e) => {
                            if (e.target.value === "") e.target.value = "0";
                          }}
                          className={classes.input}
                          min={0}
                        />
                      </td>
                    );
                  }

                /*  return (
                    <td key={cellIndex} className={classes.td}>
                      {cell}
                    </td>
                  );*/
                  return (
                    <td key={cellIndex} className={classes.td}>
                      {cellIndex === 5
                        ? parseFloat(cell).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })
                        : cell}
                    </td>
                  );
                  
                })}
                {!subtitleRow && (
                  <td className={classes.td}>{getTotal(row, sheetIndex, originalIndex).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const Summary: React.FC<SummaryProps> = ({ sheets, items, onSendSummary,readyOnly }) => {
  const classes = useStyles();
  const setSummary = useCatalogoState((state) => state.setSummaryData);
  const [totalMovimientos, setTotalMovimientos] = useState(0);
  const [resumMovimientos, setResumMovimientos] = useState([]);

  const toast = useCustomToast();
  const { setShowGenericLoader } = useUiState();
  const idForm = useGeneralFormCompleteState((state) => state.idForm);

  const getTabTotal = (sheetName: string) => {
    const total = items
      .filter(item => item.sheetName === sheetName)
      .reduce((acc, item) => acc + item.total, 0);
  
    return total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
  };


  const getTotalSum = () => {
    const sum = items.reduce((acc, item) => acc + item.total, 0);
    const sumFinal = sum + totalMovimientos;
    return sumFinal.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' });
  };
  

function generateStructResum(data) {

  const apiData = {
    summaryList: [],
    totalAllSheet: data.totalGeneral
  };

  data.details.forEach((sheet) => {
    const sheetData = {
      sheetName: sheet.sheetName,
      summaryListData: [],
      totalSheet: sheet.total
    };

    sheet.items.forEach((item) => {
      const itemData = {
        nsc_sku: item.material,
        description: item.description,
        idRow: item.rowIndex.toString(), 
        qty: item.quantity.toString(),
        total: item.total.toString() 
      };
      sheetData.summaryListData.push(itemData);
    });

    //apiData.summaryList.push(sheetData);
    if (sheetData.summaryListData.length > 0) {
      apiData.summaryList.push(sheetData);
    }

  });

  return apiData;
}



const handleSendSummary = async () => {


  console.log("resumMovimientos",resumMovimientos)
 
  const newMovimientosSheet = {
    sheetName: "Movimientos",
    total: totalMovimientos.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }),
    items: resumMovimientos.map((item, idx)  => ({
      material: "N/A",
      rowIndex: idx,
      description: item.description,
      quantity: "-",
      total: item.total,
    }))
  };

  const summaryData = {
    totalGeneral: getTotalSum(),
    details: [
      ...sheets.map(sheet => ({
        sheetName: sheet.name,
        total: getTabTotal(sheet.name),
        items: items
          .filter(item => item.sheetName === sheet.name)
          .map(item => ({
            material: item.material,
            rowIndex: item.rowIndex,
            description: item.row.cellAxList[2],
            quantity: item.quantity,
            total: item.total,
          })),
      })),
      newMovimientosSheet 
    ]
  };

  const body = generateStructResum(summaryData);
  setSummary(summaryData);
  //toast.success("Resumen guardado con éxito.");
  setShowGenericLoader(true);
  
  await GeneradoresServices.getInstance().patchSaveSummaryGenerator(idForm, body).then((data) => {
    setShowGenericLoader(false);
    toast.success("Resumen guardado con éxito.");
  }).catch(() => {
    toast.error("Algo ha sucedido de manera inesperada, intente de nuevo más tarde.");
  });

  // onSendSummary(body); // Usar endpoit Starlite
};


  if (items.length === 0) {
    return <div className={classes.noData}>No hay datos aún.</div>;
  }

  return (
    <div>
      <h3>Resumen</h3>
      {sheets.map((sheet, index) => {
        const sheetItems = items.filter(item => item.sheetName === sheet.name);
        if (sheetItems.length === 0) {
          return null;
        }

        return (
          <div key={index}>
            <h4>{sheet.name}</h4>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.th}>Fila</th>
                  <th className={classes.th}>Descripción</th>
                  <th className={classes.th}>Cantidad</th>
                  <th className={classes.th}>Total</th>
                </tr>
              </thead>
              <tbody>
                {sheetItems.map((item, itemIndex) => (
                  <tr key={itemIndex}>
                    <td className={classes.td}>{item.rowIndex + 1}</td>
                    <td className={classes.td}>{item.row.cellAxList[2]}</td>
                    <td className={classes.td}>{item.quantity}</td>
                    <td className={classes.td}>
                     {item.total.toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}                     
                    </td>
                  </tr>
                ))}
            </tbody>
              <tfoot>
                <tr>
                  <td colSpan={3} className={classes.td}>Total</td>
                  <td className={classes.td}>{""}{getTabTotal(sheet.name)}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        );
      })}

      <MovimientosTable setTotal={setTotalMovimientos} setResumMovimientos={setResumMovimientos}/>

      <h4 style={{marginLeft: '42.5%'}}>Total General: {""}{getTotalSum()}</h4>
      {
        !readyOnly && (
          <button onClick={handleSendSummary} className={classes.button}>
            Guardar
          </button>
        )
      }
    </div>
  );
};

const MovimientosTable = ({ setTotal,setResumMovimientos }) => {
  const classes = useStyles();

  const [movimientosTotal, setMovimientosTotal] = useState('0'); 
  const [noCatalogadosTotal, setNoCatalogadosTotal] = useState('0'); 

  /*const calculateFinalTotal = () => {
    const movimientosValue = parseFloat(movimientosTotal) || 0;
    const noCatalogadosValue = parseFloat(noCatalogadosTotal) || 0;
    return movimientosValue + noCatalogadosValue;
  };*/

  const parseCurrency = (value) => {
    return parseFloat(value.replace(/[^0-9.-]+/g, '')) || 0;
  };

const calculateFinalTotal = () => {
  const movimientosValue = parseCurrency(movimientosTotal);
  const noCatalogadosValue = parseCurrency(noCatalogadosTotal);
  
  return movimientosValue + noCatalogadosValue;
};

const generateResum = () => {
  const movimientosResumen = { "description": "Movimientos", "total": parseCurrency(movimientosTotal) };
  const noCatalogadosResumen = { "description": "No Catalogados", "total": parseCurrency(noCatalogadosTotal) };
  
  return [movimientosResumen, noCatalogadosResumen];
};

  useEffect(() => {
    const finalTotal = calculateFinalTotal();
    const finalResum = generateResum();

    setTotal(finalTotal); 
    setResumMovimientos(finalResum);
  }, [movimientosTotal, noCatalogadosTotal, setTotal]);

  return (
    <div>
      <h4>{"Movimientos"}</h4>
      <table className={classes.table}>
        <thead>
          <tr>
          <th className={classes.th}>Fila</th>
            <th className={classes.th}>Descripción</th>
            <th className={classes.th}>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.td}>1</td>
            <td className={classes.td}>Movimientos</td>
            <td className={classes.td}>
            <input
              type="text" 
              value={movimientosTotal}
              onChange={(e) => {
                const rawValue = e.target.value.replace(/[^0-9.-]+/g, "");
                setMovimientosTotal(rawValue);
              }}
              onFocus={(e) => {
                if (e.target.value === "0") e.target.value = "";
              }}
              onBlur={(e) => {
                if (e.target.value === "") {
                  setMovimientosTotal("0");
                } else {
                  const formattedValue = parseFloat(e.target.value).toLocaleString('es-MX', {
                    style: 'currency',
                    currency: 'MXN'
                  });
                  setMovimientosTotal(formattedValue);
                }
              }}
              className={classes.inputM}
              min={0}
            />
            </td>
          </tr>
          <tr>
            <td className={classes.td}>2</td>
            <td className={classes.td}>No catalogados</td>
            <td className={classes.td}>
              <input
                type="text" 
                value={noCatalogadosTotal}
                onChange={(e) => {
                  const rawValue = e.target.value.replace(/[^0-9.-]+/g, "");
                  setNoCatalogadosTotal(rawValue);
                }}
                onFocus={(e) => {
                  const rawValue = e.target.value.replace(/[^0-9.-]+/g, "");
                  e.target.value = rawValue === "0" ? "" : rawValue;
                }}
                onBlur={(e) => {
                  if (e.target.value === "") {
                    setNoCatalogadosTotal("0");
                  } else {
                    const formattedValue = parseFloat(e.target.value).toLocaleString('es-MX', {
                      style: 'currency',
                      currency: 'MXN'
                    });
                    setNoCatalogadosTotal(formattedValue);
                  }
                }}
                className={classes.inputM}
                min={0}
              />
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
          <td colSpan={2} className={classes.td}>Total</td>
          <td className={classes.td}>
              {calculateFinalTotal().toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
          </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};


export default Tabs;

const useStyles = makeStyles((theme) => ({
  tabList: {
    display: 'flex',
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    borderBottom: '1px solid #ccc',
  },
  tabListItem: {
    padding: '10px 20px',
    cursor: 'pointer',
    border: '1px solid #ccc',
    color: '#ffffff',
    borderTop: '1px solid rgb(24, 49, 121)',
    borderLeft: '1px solid rgb(24, 49, 121)',
    borderRight: '1px solid rgb(24, 49, 121)',
    borderBottom: 'none',
    background: 'rgb(24, 49, 121)',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px', 
    '&.active': {
      background: 'rgb(24, 49, 121)',
      fontWeight: 'bold',
      color: '#2347AD',
      backgroundColor: 'white',
      border: '2px solid #2347AD',
    },
  },
  tabContent: {
    border: '1px solid #ccc',
    padding: '20px',
    background: '#ffffff',
  },
  tabPanel: {
    display: 'none',
    '&.active': {
      display: 'block',
    },
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    border: '1px solid #2347AD',
    padding: '8px',
    color: 'white',
    backgroundColor: 'rgb(24, 49, 121)',
  },
  td: {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center',
    '&.selected': {
      backgroundColor: 'rgb(24, 49, 121)',
    },
  },
  input: {
    width: '60px',
  },
  inputM: {
    width: '100px',
  },
  subtitle: {
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: '#e6e6e6',
  },
  selected: {
    fontWeight: 'bold',
    color: '#2347AD',
    backgroundColor: '#e6e6e6',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#2347AD',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px',
    marginLeft: '45%',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  noData: {
    textAlign: 'center',
    marginTop: '20px',
  },
  searchInput: {
    width: '100%',
    padding: '8px 12px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    outline: 'none',
    boxSizing: 'border-box',
    '&:focus': {
      borderColor: '#007BFF', 
      boxShadow: '0 0 4px rgba(0, 123, 255, 0.4)' 
    }
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px', 
  },
  clearButton: {
    marginLeft: '8px', 
    padding: '8px 12px',
    fontSize: '16px',
    backgroundColor: 'rgb(24, 49, 121)',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: '#007BFF',
    },
  }
}));
